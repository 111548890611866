import { Popup } from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { ReactElement } from 'react';
import { Outlet, Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { logo } from 'assets';
import appSwitcher from 'assets/images/svg/appSwitcher.svg';
import { SuspenseWrapper } from 'routes';

import { Header, Page, Sidebar, HeaderAvatarDropdown, InfoCurrentUser } from './components';
import useModalHandlers from '../../hooks/useModalHandlers';
import { ROUTE_PATHS } from '../../settings';
import { AppSwitcherContent } from './components/AppSwitcherContent';
import useHeaderHandlers from './useHeaderHandlers';

const Wrapper = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  display: flex;

  & img {
    margin-right: 0.5rem;
  }

  &:hover {
    text-decoration: none;
  }
`;

const RightBlock = styled.div`
  width: 100%;
`;

const AppSwitcherButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  margin-right: 12px;
  padding: 5px;

  &:hover {
    padding: 5px;
    background-color: rgb(223, 225, 230);
    border-radius: 3px;
    cursor: pointer;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

const LogoAppName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.N500};
  display: flex;
  align-items: center;
`;

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

function Layout(): ReactElement {
  const { handleSignOut, avatarSrc, userFullName, userRoles } = useHeaderHandlers();
  const { isOpen, closeModal, toggleModal } = useModalHandlers();

  return (
    <>
      <Header>
        <HeaderLogo>
          <Popup
            isOpen={isOpen}
            onClose={closeModal}
            placement="bottom-start"
            content={() => <AppSwitcherContent />}
            trigger={(triggerProps) => (
              <AppSwitcherButton {...triggerProps} type="button" onClick={toggleModal}>
                <img src={appSwitcher} alt="App switcher" />
              </AppSwitcherButton>
            )}
          />
          <StyledLink to={ROUTE_PATHS.CONTRACTORS.INDEX}>
            <img src={logo} width={24} height={24} alt="logo" />
            <LogoAppName>Mission Control</LogoAppName>
          </StyledLink>
        </HeaderLogo>
        <HeaderAvatarWrapper>
          <HeaderAvatarDropdown
            handleSignOut={handleSignOut}
            avatarSrc={avatarSrc}
            userFullName={userFullName}
          />
          <InfoCurrentUser userFullName={userFullName} userRoles={userRoles} />
        </HeaderAvatarWrapper>
      </Header>
      <Wrapper>
        <Sidebar />
        <RightBlock>
          <main>
            <Page>
              <SuspenseWrapper>
                <Outlet />
              </SuspenseWrapper>
            </Page>
          </main>
        </RightBlock>
      </Wrapper>
    </>
  );
}

export default Layout;
