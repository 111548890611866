import { APPS_PERMISSIONS } from 'api';
import { logoCEP, logoERP, logoHRM, logoMC } from 'assets';

import { getAppsDictionary } from './dictionary';

type PermissionsType = {
  erp?: string[];
  ['ya-me']?: string[];
  cep?: string[];
  payments?: string[];
  mc?: string[];
};

const userHasPermissions = (permission: string[] | undefined) => {
  if (!permission) return false;
  return !!permission?.length;
};

export const getApps = (permissions: PermissionsType) => {
  const { hrm, cep, erp, mc } = getAppsDictionary;
  return [
    {
      title: `${hrm.title}.HRM`,
      subTitle: 'Human Resource Management system',
      logo: logoHRM,
      link: process.env.REACT_APP_YAME_URL,
      isVisible: userHasPermissions(permissions[APPS_PERMISSIONS.YA_ME]),
    },
    {
      title: `${cep.title}.CEP`,
      subTitle: 'Competency Evaluation Platform',
      logo: logoCEP,
      link: process.env.REACT_APP_CEP_URL,
      isVisible: userHasPermissions(permissions[APPS_PERMISSIONS.CEP]),
    },
    {
      title: `${erp.title}.ERP`,
      subTitle: 'Enterprise Resource Planning',
      logo: logoERP,
      link: process.env.REACT_APP_ERP_FRONT_URL,
      isVisible: userHasPermissions(permissions[APPS_PERMISSIONS.ERP]),
    },
    {
      title: `${mc.title}.Mission Control`,
      subTitle: 'Admin system',
      logo: logoMC,
      link: process.env.REACT_APP_MC_URL,
      isVisible: userHasPermissions(permissions[APPS_PERMISSIONS.MC]),
    },
  ];
};
