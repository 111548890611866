import Lozenge from '@atlaskit/lozenge';
import { ThemeProps, typography } from '@atlaskit/theme';
import { ReactElement } from 'react';
import styled from 'styled-components/macro';

import { RoleEntity } from 'api';

const Heading = styled.div<{ mixin: () => ThemeProps }>`
  ${(props) => props.mixin()};
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.25rem;
  line-height: 1.25rem;
`;

const Wrapper = styled.div`
  margin-left: 0.15rem;
`;

interface InfoCurrentUserProps {
  userFullName: string;
  userRoles: RoleEntity[];
}

function InfoCurrentUser({ userFullName, userRoles }: InfoCurrentUserProps): ReactElement {
  return (
    <Wrapper>
      <Heading mixin={typography.h400}>{userFullName}</Heading>
      {userRoles?.map(({ identifier, name }) => (
        <Lozenge key={identifier} appearance="inprogress" isBold>
          {name}
        </Lozenge>
      ))}
    </Wrapper>
  );
}

export default InfoCurrentUser;
