import { ThemeProps } from '@atlaskit/theme';
import styled, { css } from 'styled-components/macro';

export const Heading = styled.div<{ mixin: () => ThemeProps }>`
  ${(props) => props.mixin()};
`;

export const textOverflowStyles = css`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const WrapperCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
