import { ReactElement } from 'react';

import { useAuth } from 'hooks';

interface ErrorPage {
  onReset: () => void;
}

function ErrorPage({ onReset }: ErrorPage): ReactElement {
  const { signOut } = useAuth();

  return (
    <div title="there was an error">
      <h1>Sorry.. there was an error</h1>
      <button
        onClick={() => {
          signOut();
          onReset();
        }}
      >
        go to login page
      </button>
    </div>
  );
}

export default ErrorPage;
