import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import { MenuGroup } from '@atlaskit/menu';
import { LeftSidebarWithoutResize } from '@atlaskit/page-layout';
import { SideNavigation } from '@atlaskit/side-navigation';
import { ReactElement } from 'react';

import { ROUTE_PATHS } from 'settings';

import { NavItem } from '../navItem';

function Sidebar(): ReactElement {
  return (
    <LeftSidebarWithoutResize width={240}>
      <SideNavigation label="Ya.me navigation">
        <MenuGroup>
          <NavItem
            to={ROUTE_PATHS.CONTRACTORS.INDEX}
            icon={<UserAvatarCircleIcon label="avatar icon" />}
          >
            Associates
          </NavItem>
        </MenuGroup>
      </SideNavigation>
    </LeftSidebarWithoutResize>
  );
}

export default Sidebar;
