import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button/standard-button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import { colors } from '@atlaskit/theme';
import { ReactElement } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  color: ${colors.R400};
`;

interface HeaderAvatarDropdownProps {
  handleSignOut: () => void;
  avatarSrc?: string | null;
  userFullName: string;
}

function HeaderAvatarDropdown({
  handleSignOut,
  avatarSrc,
  userFullName,
}: HeaderAvatarDropdownProps): ReactElement {
  return (
    <DropdownMenu
      trigger={({ triggerRef, ...props }) => (
        <Button
          {...props}
          iconBefore={
            <Avatar appearance="circle" src={avatarSrc || ''} size="large" name={userFullName} />
          }
          ref={triggerRef}
          appearance="link"
        />
      )}
    >
      <DropdownItemGroup>
        <Wrapper>
          <DropdownItem onClick={handleSignOut} elemBefore={<SignOutIcon label="signout" />}>
            Sign Out
          </DropdownItem>
        </Wrapper>
      </DropdownItemGroup>
    </DropdownMenu>
  );
}

export default HeaderAvatarDropdown;
