import jwt_decode from 'jwt-decode';
import { ReactElement, useCallback, createContext, useContext, useReducer } from 'react';

import {
  AuthActions,
  authReducer,
  AuthState,
  initialAuthState,
  localStorageAccessKey,
  MC_INTERNAL_SYSTEM_CODE,
  TokenBody,
} from 'api';
import { notify } from 'components/notification';
import { ROUTE_PATHS } from 'settings';
import { remove, write } from 'utils';

interface ProvideAuth extends AuthState {
  signIn: (accessToken: string) => void;
  signOut: () => void;
}

const authContext = createContext<ProvideAuth>({
  ...initialAuthState,
  signIn: () => null,
  signOut: () => null,
});

export function ProvideAuth({ children }: { children: ReactElement }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth(): ProvideAuth {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  const clearAccessToken = (type: AuthActions.LOGOUT): void => {
    remove(localStorageAccessKey);

    dispatch({
      type,
    });
  };

  const signOut = useCallback((): void => clearAccessToken(AuthActions.LOGOUT), []);

  const signIn = useCallback(
    async (accessToken: string) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_CONTRACTOR_DIRECTORY_URL}/login`, {
          method: 'POST',
          body: JSON.stringify({
            token: accessToken,
          }),
          credentials: 'include',
        });

        if (response.status === 402) {
          signOut();
          window.location.replace(ROUTE_PATHS.PAYMENT_REQUIRED.INDEX);

          throw new Error('your subscription expired');
        }

        if (response.status === 403) {
          response.text().then((text) => {
            notify({ content: JSON.parse(text).message, type: 'info' });
          });
        }

        const data = await response.json();

        const { id, internalRoles } = jwt_decode<TokenBody>(data.token);

        if (!internalRoles[MC_INTERNAL_SYSTEM_CODE].length) {
          dispatch({ type: AuthActions.LOGOUT });
          notify({ content: 'JWT Invalid, no roles to sign in', type: 'info' });
          return;
        }

        write(localStorageAccessKey, data.token);

        dispatch({
          type: AuthActions.LOGIN,
          token: data.token,
          id,
          roles: internalRoles[MC_INTERNAL_SYSTEM_CODE],
        });
      } catch (error) {
        console.log(error);
      }
    },
    [signOut],
  );

  return {
    ...state,
    signIn,
    signOut,
  };
}
