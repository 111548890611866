import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { Contractor } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useContractorsQuery(
  options?: UseQueryOptions<Contractor[], Error>,
): UseQueryResult<Contractor[], Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<Contractor[], Error>(
    'contractors',
    async () => {
      const response = await authorisedFetch('/contractors', {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useContractorsQuery;
