import jwt_decode, { JwtPayload } from 'jwt-decode';

export function tokensTimeToLiveLeft(token: string): number {
  const { exp } = jwt_decode<JwtPayload>(token);

  if (!exp) {
    return 0;
  }

  return exp * 1000 - Date.now(); //ms
}

export function isTokenExpired(token: string): boolean {
  try {
    return tokensTimeToLiveLeft(token) <= 0;
  } catch {
    return true;
  }
}
