import { useCallback } from 'react';

import { useAuth } from 'hooks';
import { ROUTE_PATHS } from 'settings';

export default function useFetchErp() {
  const { token, signOut } = useAuth();

  const authorisedFetch = useCallback(
    (url: string, options: RequestInit = {}) => {
      const { headers, ...rest } = options;

      const response = fetch(`${process.env.REACT_APP_ERP_URL}/api/yame/v1${url}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        ...rest,
      }).then((response) => {
        if (response.status === 401) {
          // something wrong with the token, logout!
          signOut();
          throw new Error('JWT Invalid, re-login please');
        }

        if (response.status === 402) {
          signOut();
          window.location.replace(ROUTE_PATHS.PAYMENT_REQUIRED.INDEX);

          throw new Error('your subscription expired');
        }

        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }

        return response;
      });

      return response;
    },
    [signOut, token],
  );

  return authorisedFetch;
}
