export const localStorageAccessKey = '__auth_mission_control_provider_access__';

export const MC_INTERNAL_SYSTEM_CODE = 'mc';
export const YA_ME_INTERNAL_SYSTEM_CODE = 'ya-me';
export const ERP_INTERNAL_SYSTEM_CODE = 'erp';
export const PAYMENTS_INTERNAL_SYSTEM_CODE = 'payments';
export const CEP_INTERNAL_SYSTEM_CODE = 'cep';
export const CEP_VIEWER_ROLES_ID = '7bca3ade-0772-4aef-8f38-631b0a1694c9';

interface PermissionsType {
  ERP: 'erp';
  YA_ME: 'ya-me';
  CEP: 'cep';
  PAYMENTS: 'payments';
  MC: 'mc';
}

export const APPS_PERMISSIONS: PermissionsType = {
  ERP: 'erp',
  YA_ME: 'ya-me',
  PAYMENTS: 'payments',
  CEP: 'cep',
  MC: 'mc',
};

export enum COMPANY {
  yalantis = 'yalantis',
  sherp = 'sherp',
  temy = 'temy',
  sab = 'sab',
  tallium = 'tallium',
}
