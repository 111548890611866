import { COMPANY } from 'api';
import { appPathStrategy, getCompanyName } from 'utils';

const loadImg = async (path: COMPANY) => {
  const imgFormat = path === COMPANY.yalantis ? 'svg' : 'png';

  const authBackground = (await import(`./${path}/authBackground.${imgFormat}`)).default;
  const authLogo =
    path === COMPANY.yalantis ? (await import(`./${path}/authLogo.svg`)).default : '';

  return { authBackground, authLogo };
};

const companyName = getCompanyName();

export const { authBackground, authLogo } = await loadImg(appPathStrategy(companyName));
