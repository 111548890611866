import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ErpContractor } from 'api';
import { useFetchErp } from 'hooks';

function useErpContractorsQuery(
  options?: UseQueryOptions<Record<string, ErpContractor>, Error>,
): UseQueryResult<Record<string, ErpContractor>, Error> {
  const authorisedFetch = useFetchErp();

  return useQuery<Record<string, ErpContractor>, Error>(
    'erpContractors',
    async () => {
      const response = await authorisedFetch('/public/contractors', {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useErpContractorsQuery;
