import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { McRolesResponseSuccess } from 'api';
import { useFetchContractorDirectory } from 'hooks';

function useMcRolesQuery(
  options?: UseQueryOptions<McRolesResponseSuccess, Error>,
): UseQueryResult<McRolesResponseSuccess, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<McRolesResponseSuccess, Error>(
    'mc_roles',
    async () => {
      const response = await authorisedFetch(`/mc/roles`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useMcRolesQuery;
