import { useMutation, UseMutationResult } from 'react-query';

import {
  RequestArchivedContractorSuccess,
  RequestEditContractorSuccess,
  RequestManagerToReassignSuccess,
  RequestResumeContractorSuccess,
  RequestSuspendContractorSuccess,
  ResponseEditContractorSuccess,
  ResponseResumeContractorSuccess,
  ResponseSuspendedContractorSuccess,
} from 'api';
import { useFetchContractorDirectory } from 'hooks';

interface ProfileMutation {
  submitEditContractorRequest: UseMutationResult<
    ResponseEditContractorSuccess,
    Error,
    RequestEditContractorSuccess | RequestArchivedContractorSuccess
  >;
  submitEditContractorRequestById: UseMutationResult<
    ResponseEditContractorSuccess,
    Error,
    RequestManagerToReassignSuccess
  >;
  submitSuspendContractorById: UseMutationResult<
    ResponseSuspendedContractorSuccess,
    Error,
    RequestSuspendContractorSuccess
  >;
  submitResumeContractorById: UseMutationResult<
    ResponseSuspendedContractorSuccess,
    Error,
    RequestSuspendContractorSuccess
  >;
}

function useProfileMutation(): ProfileMutation {
  const authorisedFetch = useFetchContractorDirectory();

  const submitEditContractorRequest = useMutation<
    ResponseEditContractorSuccess,
    Error,
    RequestEditContractorSuccess | RequestArchivedContractorSuccess
  >(async ({ data, contractorId }) => {
    const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await response.json();
  });

  const submitEditContractorRequestById = useMutation<
    ResponseEditContractorSuccess,
    Error,
    RequestManagerToReassignSuccess
  >(async ({ data, contractorId }) => {
    const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await response.json();
  });

  const submitSuspendContractorById = useMutation<
    ResponseSuspendedContractorSuccess,
    Error,
    RequestSuspendContractorSuccess
  >(async ({ data, contractorId }) => {
    const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await response.json();
  });

  const submitResumeContractorById = useMutation<
    ResponseResumeContractorSuccess,
    Error,
    RequestResumeContractorSuccess
  >(async ({ data, contractorId }) => {
    const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await response.json();
  });

  return {
    submitEditContractorRequest,
    submitEditContractorRequestById,
    submitSuspendContractorById,
    submitResumeContractorById,
  };
}

export default useProfileMutation;
