export function read<T>(key: string): T | null {
  try {
    const stored = localStorage.getItem(key);
    if (stored === null) {
      return null;
    }
    return JSON.parse(stored);
  } catch {
    return null;
  }
}

export function write<T>(key: string, data: T): void {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.log(error);
  }
}

export function remove(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
}
