import { useQueries, UseQueryOptions } from 'react-query';

import {
  InternalSystemEntity,
  PAYMENTS_INTERNAL_SYSTEM_CODE,
  ResourcesAdaptedResponse,
  RoleEntity,
} from 'api';
import { useAuth } from 'hooks';

async function fetchResources(url: string, token: string, signOut: () => void, codeSystem: string) {
  return await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${
        codeSystem === PAYMENTS_INTERNAL_SYSTEM_CODE ? 'authBearer' : 'Bearer'
      } ${token}`,
    },
    method: 'GET',
  }).then((response) => {
    if (response.status === 401) {
      // something wrong with the token, logout!
      signOut();
      throw new Error('JWT Invalid, re-login please');
    }

    if (!response.ok) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response.json();
  });
}

function useResourceQuery(
  resources: InternalSystemEntity[],
  options?: UseQueryOptions<ResourcesAdaptedResponse[]>,
) {
  const { token, signOut } = useAuth();

  return useQueries(
    resources.map(({ code, rolesResource, name, multiSelectRole }) => {
      return {
        queryKey: ['resource', code],
        queryFn: () => fetchResources(rolesResource, token || '', signOut, code),
        select: (data: RoleEntity[]) => ({
          roles: data
            ? data.map(({ identifier, name }) => ({
                value: identifier,
                label: name,
              }))
            : [],
          code,
          name,
          multiSelectRole,
        }),
        enabled: Boolean(resources.length),
        options,
      };
    }),
  );
}

export default useResourceQuery;
