import { colors } from '@atlaskit/theme';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  position: relative;
  min-height: calc(100vh - 4.25rem);
  padding: 0 1rem 3.4375rem 1.5rem;
  background-color: ${colors.N20};
  overflow: hidden;
`;

interface PageProps {
  children: ReactNode;
}

function Page({ children }: PageProps): ReactElement {
  return <Wrapper>{children}</Wrapper>;
}

export default Page;
