import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { InternalSystemResponseSuccess } from 'api';
import { useFetchContractorDirectory } from 'hooks/index';

function useInternalSystems(
  options?: UseQueryOptions<InternalSystemResponseSuccess, Error>,
): UseQueryResult<InternalSystemResponseSuccess, Error> {
  const authorisedFetch = useFetchContractorDirectory();

  return useQuery<InternalSystemResponseSuccess, Error>(
    'internal_systems',
    async () => {
      const response = await authorisedFetch(`/internal_systems`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useInternalSystems;
