import { COMPANY } from 'api';
import { appPathStrategy, getCompanyName } from 'utils';

const loadImg = async (path: COMPANY) => {
  const logoCEP = (await import(`./${path}/CEP.png`)).default;
  const logoERP = (await import(`./${path}/ERP.png`)).default;
  const logoHRM = (await import(`./${path}/HRM.png`)).default;
  const logoMC = (await import(`./${path}/missionControl.png`)).default;

  return { logoCEP, logoERP, logoHRM, logoMC };
};

const companyName = getCompanyName();

export const { logoERP, logoCEP, logoHRM, logoMC } = await loadImg(appPathStrategy(companyName));
