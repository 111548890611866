import { colors } from '@atlaskit/theme';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.25rem;
  padding: 0.5rem 1rem;
  background-color: ${colors.N0};
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px -2px;
`;

interface HeaderProps {
  children: ReactNode;
}

function Header({ children }: HeaderProps): ReactElement {
  return <Wrapper>{children}</Wrapper>;
}

export default Header;
