import { RoleEntity } from 'api';
import { useAuth, useProfileQuery, useMcRolesQuery } from 'hooks';
import { replacePhotoSrcToSmall } from 'utils';

interface HeaderHandlers {
  handleSignOut: () => void;
  avatarSrc?: string | null;
  userFullName: string;
  userRoles: RoleEntity[];
}

function useHeaderHandlers(): HeaderHandlers {
  const { id, signOut, roles } = useAuth();
  const { data: { photo, firstName, lastName } = {} } = useProfileQuery(id || '', {
    enabled: !!id,
  });
  const handleSignOut = () => signOut();
  const { data: mcRoles } = useMcRolesQuery();

  return {
    handleSignOut,
    avatarSrc: replacePhotoSrcToSmall(photo || ''),
    userFullName: `${firstName || ''} ${lastName || ''}`,
    userRoles: mcRoles?.filter(({ identifier }) => roles?.includes(identifier)) || [],
  };
}

export default useHeaderHandlers;
