import { COMPANY } from 'api';
import { appPathStrategy, getCompanyName } from 'utils';

const path = appPathStrategy(getCompanyName());

const dictionary = {
  [COMPANY.yalantis]: {
    title: 'Mission Control',
  },
  [COMPANY.sherp]: {
    title: 'Sherp. Mission Control',
  },
};

export const indexHtmlDictionary = dictionary[path];
