import { colors } from '@atlaskit/theme';
import { ReactElement, ReactNode } from 'react';
import { Link, useMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 1rem;
  padding: 0 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2.5rem;
  text-decoration: none;
  white-space: nowrap;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Icon = styled.span`
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;

interface NavItemProps {
  to: string;
  children: ReactNode;
  icon: ReactNode;
}

function NavItem({ to, children, icon, ...props }: NavItemProps): ReactElement {
  const match = useMatch(to);

  return (
    <StyledLink
      to={to}
      style={{
        color: match ? colors.B400 : colors.N500,
        backgroundColor: match ? colors.N20A : colors.N10,
      }}
      {...props}
    >
      <Icon>{icon}</Icon>
      <span>{children}</span>
    </StyledLink>
  );
}

export default NavItem;
