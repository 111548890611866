import { COMPANY } from 'api';
import { appPathStrategy, getCompanyName } from 'utils';

const loadImg = async (path: COMPANY) => {
  const favicon = (await import(`./${path}/favicon.ico`)).default;

  return { favicon };
};

const companyName = getCompanyName();

export const { favicon } = await loadImg(appPathStrategy(companyName));
