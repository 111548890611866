import Spinner from '@atlaskit/spinner';
import { ReactNode, Suspense } from 'react';

import { WrapperCenter } from 'styles';

function SuspenseWrapper({ children }: { children: ReactNode }) {
  return (
    <Suspense
      fallback={
        <WrapperCenter>
          <Spinner size="large" />
        </WrapperCenter>
      }
    >
      {children}
    </Suspense>
  );
}

export default SuspenseWrapper;
