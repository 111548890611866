interface ParentRoute {
  INDEX: string;
}
interface RouteEntity {
  INDEX: string;
  BY_ID?: string;
}

interface AuthorizationRoutes {
  INDEX: string;
  SIGNIN: RouteEntity;
}

export interface ContractorsRoutes {
  INDEX: string;
  BY_ID: string;
  ADD_CONTRACTOR: string;
  EDIT_CONTRACTOR: string;
}

interface RoutePaths {
  ROOT: string;
  CONTRACTORS: ContractorsRoutes;
  AUTHORIZATION: AuthorizationRoutes;
  PAYMENT_REQUIRED: ParentRoute;
  APP_INFO: ParentRoute;
}

const ROUTE_PATHS: RoutePaths = {
  ROOT: '/',
  AUTHORIZATION: {
    INDEX: '/authorization',
    SIGNIN: {
      INDEX: '/authorization/sign-in',
    },
  },
  CONTRACTORS: {
    INDEX: '/contractors',
    BY_ID: '/contractors/:id/',
    ADD_CONTRACTOR: '/contractors/add-contractor',
    EDIT_CONTRACTOR: '/contractors/edit-contractor/:id',
  },
  PAYMENT_REQUIRED: {
    INDEX: '/payment-required',
  },
  APP_INFO: {
    INDEX: 'app-info',
  },
};

export default ROUTE_PATHS;
