import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ErpContractor } from 'api';
import { useFetchErp } from 'hooks';

function useErpProfileQuery(
  contractorId: string | null,
  options?: UseQueryOptions<ErpContractor>,
): UseQueryResult<ErpContractor> {
  const authorisedFetch = useFetchErp();

  return useQuery<ErpContractor>(
    ['erpProfile', contractorId],
    async () => {
      const response = await authorisedFetch(`/public/contractors/id/${contractorId}`, {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useErpProfileQuery;
