import { COMPANY } from 'api';
import { appPathStrategy, getCompanyName } from 'utils';

const pathStrategy = appPathStrategy(getCompanyName());

type Apps = 'hrm' | 'cep' | 'erp' | 'mc';
type CompanyApps = Record<Apps, { title: string }>;
type GetApps = Record<typeof pathStrategy, CompanyApps>;

const getApps: GetApps = {
  [COMPANY.yalantis]: {
    hrm: {
      title: 'Yalantis',
    },
    cep: {
      title: 'Yalantis',
    },
    erp: {
      title: 'Yalantis',
    },
    mc: {
      title: 'Yalantis',
    },
  },
  [COMPANY.sherp]: {
    hrm: {
      title: 'Sherp',
    },
    cep: {
      title: 'Sherp',
    },
    erp: {
      title: 'Sherp',
    },
    mc: {
      title: 'Sherp',
    },
  },
};
export const getAppsDictionary = getApps[pathStrategy];
