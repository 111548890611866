import { JwtPayload } from 'jwt-decode';

export interface TokenBody extends JwtPayload {
  id: string;
  internalRoles: InternalRoles;
}

export type BreadCrumbsList = {
  text: string;
  to: string;
}[];

export interface Child {
  name: string;
  dob: string;
}

export type Children = Child[];

export interface InternalRoles {
  [key: string]: string[];
}

export interface DatePeriod {
  startDate: string;
  endDate: string;
}

export interface ContractorEntity {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface TypeOfLeaveHistory {
  period: DatePeriod;
  typeOfLeave: string;
}

export interface DismissalHistory {
  dismissalReason: string;
  dismissalType: string;
  hire: boolean;
  regrettable: boolean;
  comment: string;
}

export interface Contractor {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  hireDate?: string;
  type?: string;
  deliveryUnit?: string;
  department?: string;
  team?: string;
  position?: string;
  dismissalDate?: string;
  photo?: string;
  dob?: string;
  currentCity?: string;
  currentCountry?: string;
  hrbp?: ContractorEntity | null;
  jobTitle?: string;
  grade?: string;
  pmGrade?: string;
  approvalScheme?: string;
  manager?: ContractorEntity | null;
  recruiter?: ContractorEntity | null;
  typeOfAssociation?: string;
  typeOfLeave?: string;
  englishLevel?: string;
  archived?: boolean;
  suspended?: boolean;
  jobFamily?: string;
  trialPeriod?: boolean;
  trialPeriodEndedOn?: string;
  typeOfLeavePeriod?: DatePeriod;
  typeOfLeaveHistory?: TypeOfLeaveHistory | null;
  children?: Children;
  personalEmail?: string;
  postalAddress?: string;
  phoneNumber?: string;
  gender?: string;
  internalRoles?: {
    [key: string]: string[];
  };
  dismissalType?: string;
  dismissalReason?: string;
  hire?: boolean;
  regrettable?: boolean;
  comment?: string;
  dismissalHistory?: DismissalHistory[];
  cooperationHistory?: DatePeriod[];
  createDate?: string;
  updateDate?: string;
  yob?: string;
}

export type RequestAddContractorFormData = {
  firstName: string;
  lastName: string;
  email: string;
  hireDate: string;
  jobProfile: Options<number | null, string> | null;
  recruiter: Options<string | null, string> | null;
  dismissalDate?: string | null;
  personalEmail: string;
  phoneNumber: string;
  internalRoles: { [key: string]: Options<string, string>[] } | null;
};

export interface RequestAddContractorSuccess {
  firstName: string;
  lastName: string;
  email: string;
  hireDate: string;
  internalRoles: InternalRoles | null;
  jobProfileID?: unknown | null;
  dismissalDate?: string | null;
  jobProfileName?: string | null;
  personalEmail: string;
  phoneNumber: string;
  recruiter: string | null;
}

export interface Options<T, U> {
  value: T;
  label: U;
}

export interface RequestEditContractorSuccess {
  data: RequestAddContractorSuccess;
  contractorId: string;
}

export interface ResponseEditContractorSuccess {
  message: string;
}

export interface ResponseSuspendedContractorSuccess {
  message: string;
}

export interface ResponseResumeContractorSuccess {
  message: string;
}

export interface RequestArchivedContractorSuccess {
  data: {
    archived: boolean;
  };
  contractorId: string;
}

export interface RequestManagerToReassignSuccess {
  data: {
    archived: boolean;
    managerToReassign: string;
  };
  contractorId: string;
}

export interface RequestSuspendContractorSuccess {
  data: {
    suspended: boolean;
  };
  contractorId: string;
}

export interface RequestResumeContractorSuccess {
  data: {
    suspended: boolean;
  };
  contractorId: string;
}

export enum ContractorsTabsEnum {
  Associates = 'Associates',
  Archived = 'Archived',
  Suspended = 'Suspended',
  Feature = 'Future associate',
}

export enum ArchiveStatus {
  notArchived = 'notArchived',
  archived = 'archived',
  suspended = 'suspended',
  feature = 'feature',
}

export type ArchiveStatusTypes = typeof ArchiveStatus[keyof typeof ArchiveStatus];

export interface Department {
  id: number;
  name: string;
  category: string;
}

export type ResponseDepartmentsSuccess = Department[];

export interface ContractorWithFullName extends Contractor {
  fullName: string;
  jobProfile: string;
  propertiesForFilter: {
    hireDate: Contractor['hireDate'];
    dismissalDate: Contractor['dismissalDate'];
    jobProfile: ErpContractor['jobProfile'];
    roleYaMe: string[];
    roleMc: string[];
    roleErp: string[];
  };
}

export interface SeparatedContractorsByArchive {
  notArchived: ContractorWithFullName[];
  archived: ContractorWithFullName[];
  suspended: ContractorWithFullName[];
  feature: ContractorWithFullName[];
}

export interface DaysOffInfo {
  paidVacationUsed: number;
  paidVacationLeft: number;
  paidLastYearVacationLeft: number;
  unpaidVacationUsed: number;
  documentedSickLeavesUsed: number;
  documentedSickLeavesLeft: number;
  undocumentedSickLeavesUsed: number;
  undocumentedSickLeavesLeft: number;
  additionalDaysOffUsed: number;
}

export interface ErpContractor {
  daysOffInfo?: DaysOffInfo | null;
  departmentLead?: ContractorEntity | null;
  email?: string;
  id: string;
  jobProfile?: string;
  pms?: ErpProjectManager[];
  projects?: ErpProject[];
  availability?: Availability | null;
}

export interface ErpProject {
  unit: string;
  name: string;
  pm: ErpProjectManager | null;
}

export interface ErpProjectManager {
  id: number;
  email: string;
  name: string;
}

export interface Availability {
  status: AvailabilityStatusTypes;
  until: string | null;
}

export enum AvailabilityStatus {
  Available = 'available',
  TimeOff = 'time off',
}

export type AvailabilityStatusTypes = typeof AvailabilityStatus[keyof typeof AvailabilityStatus];

export interface CooperationHistory {
  startDate: string;
  endDate: string;
}

export interface AssociatesFiltersOptions {
  jobProfileOptions: Options<string, string>[];
  roleYaMeOptions: Options<string, string>[];
  roleMcOptions: Options<string, string>[];
  roleErpOptions: Options<string, string>[];
}

export interface DateFieldOptionType {
  startDate?: string;
  endDate?: string;
}

export type DateFieldValuesFromRef = Pick<ProfileFilters, 'hireDate' | 'dismissalDate'>;

export interface ProfileFilters {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  dismissalDate: [DateFieldOptionType] | string | any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  hireDate: [DateFieldOptionType] | string | any;
  jobProfile: string[];
  roleYaMe: string[];
  roleMc: string[];
  roleErp: string[];
}

export interface ErpContractors {
  [key: string]: ErpContractor;
}

export interface RoleEntity {
  identifier: string;
  name: string;
}

export type McRolesResponseSuccess = RoleEntity[];

export interface InternalSystemEntity {
  code: string;
  name: string;
  rolesResource: string;
  multiSelectRole: boolean;
}

export type InternalSystemResponseSuccess = InternalSystemEntity[];

export interface Resources {
  roles: Options<string, string>[];
  code: string;
  name: string;
  multiSelectRole?: boolean;
}

export interface ResourcesAdaptedResponse {
  data?: Resources;
  isLoading: boolean;
}
