import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import useFetchErp from './useFetchErp';

type ManagersResponse = {
  cdID: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
};
function useManagersQuery(
  options?: UseQueryOptions<ManagersResponse[], Error>,
): UseQueryResult<ManagersResponse[], Error> {
  const authorisedFetch = useFetchErp();

  return useQuery<ManagersResponse[], Error>(
    'managers',
    async () => {
      const response = await authorisedFetch('/public/managers', {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useManagersQuery;
