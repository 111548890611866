import jwt_decode from 'jwt-decode';

import { read } from 'utils';

import { localStorageAccessKey, MC_INTERNAL_SYSTEM_CODE } from './constants';
import { isTokenExpired } from './helpers';
import { TokenBody } from './types';

export enum AuthActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface AuthState {
  isAuth: boolean;
  token: string | null;
  id: string | null;
  roles: string[] | null;
}

type Action =
  | {
      type: AuthActions.LOGIN;
      token: AuthState['token'];
      id: AuthState['id'];
      roles: string[];
    }
  | { type: AuthActions.LOGOUT };

const token = read<string>(localStorageAccessKey);
const isTokenValid = !(
  !token ||
  isTokenExpired(token) ||
  !jwt_decode<TokenBody>(token).internalRoles ||
  !jwt_decode<TokenBody>(token).internalRoles[MC_INTERNAL_SYSTEM_CODE].length
);

export const initialAuthState: AuthState = {
  isAuth: isTokenValid,
  token: isTokenValid ? token : null,
  id: isTokenValid ? jwt_decode<TokenBody>(token).id : null,
  roles: isTokenValid ? jwt_decode<TokenBody>(token).internalRoles[MC_INTERNAL_SYSTEM_CODE] : null,
};

export function authReducer(state: AuthState, action: Action) {
  switch (action.type) {
    case AuthActions.LOGIN:
      return {
        ...state,
        isAuth: true,
        token: action.token,
        id: action.id,
        roles: action.roles,
      };
    case AuthActions.LOGOUT:
      return { ...state, isAuth: false, token: null, id: null, roles: null };
    default:
      return state;
  }
}
