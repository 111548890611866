import {
  Contractor,
  ContractorWithFullName,
  ErpContractor,
  ERP_INTERNAL_SYSTEM_CODE,
  MC_INTERNAL_SYSTEM_CODE,
  SeparatedContractorsByArchive,
  YA_ME_INTERNAL_SYSTEM_CODE,
} from 'api';

const getPropertiesForFilter = (
  contractor: Contractor,
  initialErpContractors: Record<string, ErpContractor>,
): ContractorWithFullName['propertiesForFilter'] => {
  const id = contractor.id || '';
  const contractorYaMeRoles = contractor.internalRoles?.[YA_ME_INTERNAL_SYSTEM_CODE];
  const contractorMcRoles = contractor.internalRoles?.[MC_INTERNAL_SYSTEM_CODE];
  const contractorErpRoles = contractor.internalRoles?.[ERP_INTERNAL_SYSTEM_CODE];

  return {
    dismissalDate: contractor.dismissalDate || '',
    hireDate: contractor.hireDate || '',
    jobProfile: initialErpContractors[id] ? initialErpContractors[id].jobProfile || '' : '',
    roleYaMe: contractorYaMeRoles?.length ? contractorYaMeRoles : [''],
    roleMc: contractorMcRoles?.length ? contractorMcRoles : [''],
    roleErp: contractorErpRoles?.length ? contractorErpRoles : [''],
  };
};

function separateContractors(
  initialContractors: Contractor[],
  initialErpContractors: Record<string, ErpContractor>,
): Pick<SeparatedContractorsByArchive, 'notArchived' | 'archived' | 'suspended' | 'feature'> {
  const notArchived: ContractorWithFullName[] = [];
  const archived: ContractorWithFullName[] = [];
  const suspended: ContractorWithFullName[] = [];
  const feature: ContractorWithFullName[] = [];

  initialContractors.forEach((contractor) => {
    const fullName = `${contractor.firstName} ${contractor.lastName}`;
    const id = contractor.id || '';
    const updatedContractor = {
      ...contractor,
      fullName,
      jobProfile: initialErpContractors[id]?.jobProfile || '',
      propertiesForFilter: getPropertiesForFilter(contractor, initialErpContractors),
    };

    switch (true) {
      case contractor.typeOfAssociation === 'Future associate' && !contractor.archived:
        feature.push(updatedContractor);
        break;
      case contractor.archived:
        archived.push(updatedContractor);
        break;
      case contractor.suspended:
        suspended.push(updatedContractor);
        break;
      default:
        notArchived.push(updatedContractor);
        break;
    }
  });

  return {
    notArchived,
    archived,
    suspended,
    feature,
  };
}

export default separateContractors;
