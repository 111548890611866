import { COMPANY } from 'api';

export const appPathStrategy = (name: COMPANY) => {
  switch (name) {
    case COMPANY.yalantis:
      return COMPANY.yalantis;

    case COMPANY.tallium:
    case COMPANY.sab:
    case COMPANY.temy:
    case COMPANY.sherp:
      return COMPANY.sherp;

    default:
      return COMPANY.sherp;
  }
};
