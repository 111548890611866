import { GoogleOAuthProvider } from '@react-oauth/google';
import { ReactElement } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import { NotificationsContainer } from 'components/notification';
import { ErrorBoundary } from 'features/errors';
import { ProvideAuth } from 'hooks';
import { AppRoutes, SuspenseWrapper } from 'routes';
import { GlobalStyle } from 'styles';

import 'react-toastify/dist/ReactToastify.css';
import '@atlaskit/css-reset';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App(): ReactElement {
  return (
    <>
      <GlobalStyle />
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
        <ProvideAuth>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <ErrorBoundary>
                <SkeletonTheme baseColor="#F4F5F7" highlightColor="#DFE1E6">
                  <SuspenseWrapper>
                    <AppRoutes />
                  </SuspenseWrapper>
                </SkeletonTheme>
                {process.env.REACT_APP_ENVIRONMENT !== 'prod' ? (
                  <ReactQueryDevtools initialIsOpen={false} />
                ) : null}
              </ErrorBoundary>
            </QueryClientProvider>
          </BrowserRouter>
        </ProvideAuth>
      </GoogleOAuthProvider>
      <NotificationsContainer />
    </>
  );
}

export default App;
